import { helpers, maxValue, minLength } from "@vuelidate/validators";
import {
  helpers as helpersOld,
  maxValue as maxValueOld,
  minLength as minLengthOld
} from "vuelidate/lib/validators";
import majorityDate from "@/contstants/majorityDate";

export const isCyrillic = (value: unknown): boolean => {
  const valueStr = value as string;
  const regExp = new RegExp("^([-А-Яа-яЁё'`]* ?)*$");
  return !helpers.req(value) || regExp.test(valueStr);
};
export const isCyrillicOld = (value: unknown): boolean => {
  const valueStr = value as string;
  const regExp = new RegExp("^([-А-Яа-яЁё'`]* ?)*$");
  return !helpersOld.req(value) || regExp.test(valueStr);
};
export const isLatin = (value: string): boolean => {
  const regExp = new RegExp("^([-A-Za-z'`]* ?)*$");
  return !helpers.req(value) || regExp.test(value);
};
export const isLatinOld = (value: string): boolean => {
  const regExp = new RegExp("^([-A-Za-z'`]* ?)*$");
  return !helpersOld.req(value) || regExp.test(value);
};
export const notCyrillic = (value: unknown): boolean => {
  const valueStr = value as string;
  const regExp = new RegExp("^[^А-яЁё]*$");
  return !helpers.req(value) || regExp.test(valueStr);
};
export const notCyrillicOld = (value: unknown): boolean => {
  const valueStr = value as string;
  const regExp = new RegExp("^[^А-яЁё]*$");
  return !helpersOld.req(value) || regExp.test(valueStr);
};

export const hasFullName = (fullName: string): boolean => {
  return (
    !helpers.req(fullName) ||
    fullName
      .trim()
      .split(" ")
      .filter(name => name !== "").length === 3
  );
};
export const hasFullNameOld = (fullName: string): boolean => {
  return (
    !helpersOld.req(fullName) ||
    fullName
      .trim()
      .split(" ")
      .filter(name => name !== "").length === 3
  );
};

export const twoWordsInName = (fullName: string): boolean => {
  return (
    !helpers.req(fullName) ||
    fullName
      .trim()
      .split(" ")
      .filter(name => name !== "").length >= 2
  );
};
export const twoWordsInNameOld = (fullName: string): boolean => {
  return (
    !helpersOld.req(fullName) ||
    fullName
      .trim()
      .split(" ")
      .filter(name => name !== "").length >= 2
  );
};

export const majorityMaxDate = maxValue(+majorityDate);
export const majorityMaxDateOld = maxValueOld(+majorityDate);
export const phoneMinLength = minLength(16);
export const phoneMinLengthOld = minLengthOld(16);
